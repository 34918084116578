<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22">
        <path
            fill-rule="nonzero"
            d="M3.60142857 22c-.61523809 0-1.14619047-.2233914-1.59285714-.6701741-.44619048-.4463064-.66928572-.9771585-.66928572-1.5925565V3.39373863H0V1.13100805h6.04142857V0h7.88714283v1.13100805H20v2.26273058h-1.3392857V19.7372694c0 .615398-.2230953 1.1462501-.6692857 1.5925565C17.5447619 21.7766086 17.0138095 22 16.3985714 22H3.60142857ZM16.3985714 3.39373863H3.60142857V19.7372694H16.3985714V3.39373863ZM6.45857143 17.295921h2.11285714V5.80507924H6.45857143V17.295921Zm4.96999997 0h2.1128572V5.80507924h-2.1128572V17.295921ZM3.60142857 3.39373863V19.7372694 3.39373863Z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
